<template>
	<label class="form__label" v-if="data">
		<span class="visually-hidden">{{ data.label }}</span>
		<input
			type="text"
			class="form__input"
			:placeholder="data.label"
			v-model.trim="data.value"
			@blur="data.events"
			@input="queryName"
			:class="{
				'form__input--error': data.error,
			}"
			required
		/>
		<transition name="filter__slide">
			<perfect-scrollbar
				class="filter__box"
				v-if="suggestionsName.name && queryNameList"
			>
				<div class="filter__list">
					<button
						class="filter__item"
						type="button"
						v-for="(item, index) in suggestionsName.name"
						:key="index"
						@click="setName(item.value)"
					>
						{{ item.value }}
					</button>
				</div>
			</perfect-scrollbar>
		</transition>
		<span class="form__input-helper text-red" v-if="data.error"
			>Разрешены только русские буквы</span
		>
	</label>
</template>

<script>
export default {
	name: 'FormNameInput',

	props: ['data'],

	data() {
		return {
			queryNameList: false,
		}
	},

	methods: {
		queryName() {
			const data = {
				count: 30,
				from_bound: {},
				query: this.data.value,
				to_bound: {},
			}

			if (this.data.value.length > 0) {
				this.queryNameList = true
				this.$store.dispatch('GET_DADATA', { type: this.data.type, data })
			} else {
				this.queryNameList = false
				this.$store.commit('SET_DADATA', { type: this.data.type, data: null })
			}
		},

		setName(name) {
			this.data.value = name
		},
	},

	computed: {
		suggestionsName() {
			return this.$store.getters.DADATA
		},
	},
}
</script>
